<template>
  <v-card :flat="flat">
    <campaign-image
      :src="campaign.image_url"
      :editMode="editMode"
      :campaign="campaign"
    ></campaign-image>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid grid-list-xl class="p-0">
        <v-layout row wrap>
          <v-flex xs12>
            <!-- edit name -->
            <popup-to-dialog-adapter
              title="Campaign name"
              :allowSubmit="
                campaignCopy.name &&
                  campaignCopy.name.length >= 10 &&
                  campaignCopy.name.length <= 64
              "
              :editMode="editMode"
              @submit="saveEditChanges('name', campaignCopy.name)"
              @open="updateCopyValue($event, 'name', campaign.name)"
              ref="campaignNameAdapter"
            >
              <template v-slot:activator>
                <div
                  class="p-2 transition d-flex flex-column"
                  @mouseover="hoverIndex = 1"
                  @mouseout="hoverIndex = null"
                  :class="{
                    'elevation-2': hoverIndex === 1 && editMode,
                    'cursor-pointer': editMode,
                  }"
                >
                  <h3 class="headline mb-0 primary--text">
                    {{ campaign.name }}
                  </h3>

                  <div>
                    <v-img
                      width="30"
                      :src="'/img/flags/' + campaign.country.flag"
                      :alt="campaign.country.name"
                      class="rounded-sm"
                    />
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="campaign-edit-name d-flex w-100 p-2">
                  <v-form
                    @submit="
                      $refs.campaignNameAdapter.submit(
                        $vuetify.breakpoint.smAndUp ? 'popup' : 'dialog',
                        $event
                      )
                    "
                    class="w-100"
                  >
                    <v-text-field
                      v-model="campaignCopy.name"
                      label="Campaign name"
                      hint="Min. 10 characters"
                      persistent-hint
                      :counter="64"
                      v-validate="'min:10|max:64'"
                      data-vv-name="Campaign name"
                      :error-messages="errors.collect('Campaign name')"
                      data-vv-validate-on="blur"
                    >
                      <template
                        v-if="$vuetify.breakpoint.smAndUp"
                        slot="append"
                      >
                        <emoji-picker
                          close-on-content-click
                          @emoji="campaignCopy.name += $event"
                        ></emoji-picker>
                      </template>
                    </v-text-field>
                  </v-form>
                </div>
              </template>
            </popup-to-dialog-adapter>
            <div
              class="d-flex p-2 pb-0 accent--text"
              v-if="!isBusiness && !isInfluencer"
            >
              <router-link
                :to="{
                  name: 'business',
                  params: { id: campaign.business.uuid },
                }"
              >
                <span>
                  {{ campaign.business.display_name }}
                </span>
              </router-link>
            </div>
            <div class="d-flex p-2 pb-0" v-else-if="isInfluencer">
              <span>
                {{ campaign.business.display_name }}
              </span>
            </div>
          </v-flex>
          <v-flex class="campaign-text primary--text" lg9 md8 sm8 xs12>
            <!-- description 1 -->
            <v-flex v-if="campaign.business.description" class="mb-3 p-0">
              <div class="headline pl-2">About</div>
              <popup-to-dialog-adapter
                title="About"
                :onlyDialog="true"
                :allowSubmit="
                  stripHtmlTags(businessDescription).length >= 100 &&
                    stripHtmlTags(businessDescription).length <= 1000
                "
                :editMode="editMode"
                @submit="saveBusinessDescription()"
                @open="openBusinessDescription($event)"
              >
                <template v-slot:activator>
                  <div
                    class="p-2 transition"
                    @mouseover="hoverIndex = 2"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 2 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <pre
                      class="body-1 d-block"
                      v-html="campaign.business.description"
                    ></pre>
                  </div>
                </template>
                <template v-slot:content>
                  <v-flex class="p-0">
                    <simple-rich-text-input
                      v-model="businessDescription"
                      :height="200"
                      :minlength="100"
                      :maxlength="1000"
                      :textStrength="{
                        mid: 282,
                        high: 413,
                      }"
                      ref="businessDescriptionRef"
                    ></simple-rich-text-input>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
            </v-flex>
            <!-- description 2 -->
            <v-flex v-if="campaign.product_description" class="mb-3 p-0">
              <div class="headline pl-2">Product and target audience</div>
              <popup-to-dialog-adapter
                title="Product and target audience"
                :onlyDialog="true"
                :allowSubmit="
                  stripHtmlTags(campaignCopy.product_description).length >=
                    100 &&
                    stripHtmlTags(campaignCopy.product_description).length <=
                      550
                "
                :editMode="editMode"
                @submit="
                  saveEditChanges(
                    'product_description',
                    campaignCopy.product_description
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'product_description',
                    campaign.product_description,
                    'productDescriptionRef'
                  )
                "
              >
                <template v-slot:activator>
                  <div
                    class="p-2 transition"
                    @mouseover="hoverIndex = 3"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 3 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <pre
                      class="body-1 d-block"
                      v-html="campaign.product_description"
                    ></pre>
                  </div>
                </template>
                <template v-slot:content>
                  <v-flex class="p-0">
                    <simple-rich-text-input
                      v-model="campaignCopy.product_description"
                      :height="200"
                      :minlength="100"
                      :maxlength="550"
                      :textStrength="{
                        mid: 217,
                        high: 384,
                      }"
                      ref="productDescriptionRef"
                    ></simple-rich-text-input>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
            </v-flex>
            <!-- description 3 -->
            <v-flex
              v-if="campaign.promotion_material_description"
              class="mb-3 p-0"
            >
              <div class="headline pl-2">What do you get</div>
              <popup-to-dialog-adapter
                title="Hvad får du"
                :onlyDialog="true"
                :allowSubmit="
                  stripHtmlTags(campaignCopy.promotion_material_description)
                    .length >= 100 &&
                    stripHtmlTags(campaignCopy.promotion_material_description)
                      .length <= 550
                "
                :editMode="editMode"
                @submit="
                  saveEditChanges(
                    'promotion_material_description',
                    campaignCopy.promotion_material_description
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'promotion_material_description',
                    campaign.promotion_material_description,
                    'promotionDescriptionRef'
                  )
                "
              >
                <template v-slot:activator>
                  <div
                    class="p-2 transition"
                    @mouseover="hoverIndex = 4"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 4 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <pre
                      class="body-1 d-block"
                      v-html="campaign.promotion_material_description"
                    ></pre>
                  </div>
                </template>
                <template v-slot:content>
                  <v-flex class="pl-2">
                    <simple-rich-text-input
                      v-model="campaignCopy.promotion_material_description"
                      :height="200"
                      :minlength="100"
                      :maxlength="550"
                      :textStrength="{
                        mid: 217,
                        high: 335,
                      }"
                      ref="promotionDescriptionRef"
                    ></simple-rich-text-input>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
            </v-flex>
            <!-- description 4 -->
            <v-flex
              v-if="campaign.recommendations_description"
              class="mb-3 p-0"
            >
              <div class="headline pl-2">Recommendations</div>
              <popup-to-dialog-adapter
                title="Recommendations"
                :onlyDialog="true"
                :allowSubmit="
                  stripHtmlTags(campaignCopy.recommendations_description)
                    .length >= 100 &&
                    stripHtmlTags(campaignCopy.recommendations_description)
                      .length <= 550
                "
                :editMode="editMode"
                @submit="
                  saveEditChanges(
                    'recommendations_description',
                    campaignCopy.recommendations_description
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'recommendations_description',
                    campaign.recommendations_description,
                    'recommendationsDescriptionRef'
                  )
                "
              >
                <template v-slot:activator>
                  <div
                    class="p-2 transition"
                    @mouseover="hoverIndex = 5"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 5 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <pre
                      class="body-1 d-block"
                      v-html="campaign.recommendations_description"
                    ></pre>
                  </div>
                </template>
                <template v-slot:content>
                  <v-flex class="p-0">
                    <simple-rich-text-input
                      v-model="campaignCopy.recommendations_description"
                      :height="200"
                      :minlength="100"
                      :maxlength="550"
                      :textStrength="{
                        mid: 175,
                        high: 227,
                      }"
                      ref="recommendationsDescriptionRef"
                    ></simple-rich-text-input>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
            </v-flex>
            <!-- description 5 -->
            <v-flex v-if="campaign.description" class="mb-3 p-0">
              <div
                v-if="campaign.business.description || hasMultipleDescriptions"
                class="headline pl-2"
              >
                General
              </div>
              <popup-to-dialog-adapter
                title="General"
                :onlyDialog="true"
                :allowSubmit="
                  stripHtmlTags(campaignCopy.description).length >= 100 &&
                    stripHtmlTags(campaignCopy.description).length <= 1000
                "
                :editMode="editMode"
                @submit="
                  saveEditChanges('description', campaignCopy.description)
                "
                @open="
                  updateCopyValue(
                    $event,
                    'description',
                    campaign.description,
                    'defaultDescriptionRef'
                  )
                "
              >
                <template v-slot:activator>
                  <div
                    class="p-2 transition"
                    @mouseover="hoverIndex = 6"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 6 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <pre
                      class="body-1 d-block"
                      v-html="campaign.description"
                    ></pre>
                  </div>
                </template>
                <template v-slot:content>
                  <v-flex class="p-0">
                    <simple-rich-text-input
                      v-model="campaignCopy.description"
                      :height="200"
                      :minlength="100"
                      :maxlength="1000"
                      :textStrength="{
                        mid: 282,
                        high: 413,
                      }"
                      ref="defaultDescriptionRef"
                    ></simple-rich-text-input>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
            </v-flex>
          </v-flex>
          <v-flex lg3 md4 sm4>
            <v-list tile dense class="p-0">
              <v-subheader class="light-grey">Dates</v-subheader>
              <!-- start date -->
              <popup-to-dialog-adapter
                title="Start"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="
                  isModerator || (isBusiness && campaign.starting_at > minDate)
                "
                @submit="
                  saveEditChanges('starting_at', campaignCopy.starting_at)
                "
                @open="
                  updateCopyValue($event, 'starting_at', campaign.starting_at)
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 7"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 7 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon"
                        >fal fa-calendar-plus</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="campaign.starting_at">
                        {{
                          campaign.starting_at
                            | utc_to_local_datetime("D MMMM YYYY")
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Start</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <date-picker
                    :value="startingAt"
                    label="Start date"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @change="startDateChange"
                    :disabled="false"
                  ></date-picker>
                </template>
              </popup-to-dialog-adapter>
              <!-- end date -->
              <popup-to-dialog-adapter
                title="End"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="
                  isModerator ||
                    (isBusiness && activeCampaigns <= allowedActiveCampaigns)
                "
                @submit="saveEditChanges('ending_at', campaignCopy.ending_at)"
                @open="updateCopyValue($event, 'ending_at', campaign.ending_at)"
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 8"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 8 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon"
                        >fal fa-calendar-minus</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="campaign.ending_at">
                        {{
                          campaign.ending_at
                            | utc_to_local_datetime("D MMMM YYYY")
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Completed</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <date-picker
                    :value="endingAt"
                    label="End date"
                    :min-date="isModerator ? minDate : minEndDate"
                    :max-date="maxDate"
                    @change="endDateChange"
                    :disabled="false"
                  ></date-picker>
                </template>
              </popup-to-dialog-adapter>
              <v-subheader class="light-grey">Visibility</v-subheader>
              <!-- edit is campaign hidden -->
              <popup-to-dialog-adapter
                title="Landing page"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator"
                @submit="saveEditChanges('hidden', campaignCopy.hidden)"
                @open="updateCopyValue($event, 'hidden', campaignCopy.hidden)"
              >
                <template v-slot:activator>
                  <v-list-item
                    @mouseover="hoverIndex = 'isHidden'"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 'isHidden' && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon" v-if="campaignCopy.hidden"
                        >fal fa-eye-slash</v-icon
                      >
                      <v-icon class="mt-1 menu-icon" v-else>fal fa-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ campaignCopy.hidden ? "Hidden" : "Public" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Visibility</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <div class="d-flex flex-column">
                    <v-switch
                      class="mt-1 menu-icon"
                      label="Hidden"
                      v-model="campaignCopy.hidden"
                    ></v-switch>
                  </div>
                </template>
              </popup-to-dialog-adapter>

              <v-subheader class="light-grey">Media</v-subheader>
              
              <!-- min. followers -->
              <popup-to-dialog-adapter
                title="Min. follower requirements"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="editMode"
                @submit="
                  saveEditChanges('min_followers', campaignCopy.min_followers)
                "
                @open="
                  updateCopyValue(
                    $event,
                    'min_followers',
                    campaign.min_followers
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 10"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 10 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-users</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="campaign.min_followers">
                        {{ campaign.min_followers | local_numbers }}
                      </v-list-item-title>
                      <v-list-item-title v-else>No limit</v-list-item-title>
                      <v-list-item-subtitle
                        >Min. follower requirements</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <v-select
                    label="Min. follower requirements"
                    v-model="campaignCopy.min_followers"
                    :items="minFollowersArray"
                    item-text="label"
                    :return-object="false"
                    class="mb-3"
                  ></v-select>
                </template>
              </popup-to-dialog-adapter>
              <v-subheader class="light-grey">Settings</v-subheader>
              <!-- edit kategory -->
              <popup-to-dialog-adapter
                title="Category"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator"
                @submit="
                  saveEditChanges(
                    'channel_category_id',
                    Number(tempCategory.id),
                    true
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'channel_category_id',
                    Number(campaign.channel_category_id)
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 11"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 11 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="campaign.category" class="mt-1 menu-icon">{{
                        campaign.category.icon
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="campaign.category">
                        {{ campaign.category.display_name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Category</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <channel-category-select
                    :returnObject="true"
                    :hasIcons="true"
                    :multiple="false"
                    v-model="campaignCopy.category.id"
                    @input="tempCategory = $event"
                    class="mt-4"
                  ></channel-category-select>
                </template>
              </popup-to-dialog-adapter>
              <!-- deep link -->
              <popup-to-dialog-adapter
                title="Deep link"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="allowDeepLinking"
                @submit="
                  saveEditChanges(
                    'allow_deep_linking',
                    campaignCopy.allow_deep_linking
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'allow_deep_linking',
                    campaign.allow_deep_linking
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 12"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2':
                        hoverIndex === 12 && allowDeepLinking && editMode,
                      'cursor-pointer': allowDeepLinking && editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon"
                        >fal fa-external-link</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="campaign.allow_deep_linking !== null"
                      >
                        {{ campaign.allow_deep_linking ? "Yes" : "No" }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Deep link</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <v-switch
                    v-model="campaignCopy.allow_deep_linking"
                    label="Option to link to subpage"
                    :disabled="!allowDeepLinking"
                  ></v-switch>
                </template>
              </popup-to-dialog-adapter>
              <!-- edit landing page -->
              <popup-to-dialog-adapter
                title="Landing page"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator"
                @submit="saveEditChanges('url', redirectUrl)"
                @open="updateCopyValue($event, 'url', campaign.url)"
              >
                <template v-slot:activator>
                  <v-list-item
                    @mouseover="hoverIndex = 13"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 13 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">language</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="campaign.url">
                        <a :href="campaign.url" target="_blank">
                          See page
                        </a>
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Landing page</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <div class="d-flex flex-column">
                    <v-select
                      v-model="landingSelectValue"
                      label="Choose landing page"
                      :items="[
                        {
                          text: stripProtocol(campaign.business.website),
                          value: stripProtocol(campaign.business.website),
                        },
                        { text: 'User defined', value: 'custom' },
                      ]"
                      class="mb-2"
                      @change="selectLandingUrl"
                    ></v-select>
                    <v-text-field
                      v-show="displayRedirectInput"
                      label="Insert link"
                      persistent-hint
                      hint="The website URL customers are refered to"
                      v-model="redirectUrl"
                      v-validate="{
                        required: true,
                        business_website_match: true,
                      }"
                      data-vv-scope="redirect_url"
                      data-vv-name="redirect_url"
                      data-vv-as="URL'en"
                      :error-messages="
                        errors.collect('redirect_url.redirect_url')
                      "
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </template>
              </popup-to-dialog-adapter>

              <!-- edit utm_campaign fields -->
              <popup-to-dialog-adapter
                title="Landing page"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator || isBusiness"
                @submit="
                  saveEditChanges('utm_campaign', campaignCopy.utm_campaign)
                "
                @open="
                  updateCopyValue($event, 'utm_campaign', campaign.utm_campaign)
                "
                v-if="!isInfluencer"
              >
                <template v-slot:activator>
                  <v-list-item
                    @mouseover="hoverIndex = 'utmCampaign'"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 'utmCampaign' && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <template v-if="campaign.utm_campaign">{{
                          campaign.utm_campaign
                        }}</template>
                        <i v-else>X</i>
                      </v-list-item-title>
                      <v-list-item-title>utm campaign</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <div class="d-flex flex-column">
                    <v-text-field
                      label="Campaign utm"
                      persistent-hint
                      hint=""
                      v-model="campaignCopy.utm_campaign"
                      data-vv-scope="utm_campaign"
                      data-vv-name="utm_campaign"
                      data-vv-as="URL'en"
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </template>
              </popup-to-dialog-adapter>

              <!-- edit utm_medium fields -->
              <popup-to-dialog-adapter
                title="Landing page"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator || isBusiness"
                @submit="saveEditChanges('utm_medium', campaignCopy.utm_medium)"
                @open="
                  updateCopyValue($event, 'utm_medium', campaign.utm_medium)
                "
                v-if="!isInfluencer"
              >
                <template v-slot:activator>
                  <v-list-item
                    @mouseover="hoverIndex = 'utmMedium'"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 'utmMedium' && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <template v-if="campaign.utm_medium">{{
                          campaign.utm_medium
                        }}</template>
                        <i v-else>X</i>
                      </v-list-item-title>
                      <v-list-item-title>utm medium field</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <div class="d-flex flex-column">
                    <v-text-field
                      label="Medium utm"
                      persistent-hint
                      hint=""
                      v-model="campaignCopy.utm_medium"
                      data-vv-scope="utm_medium"
                      data-vv-name="utm_medium"
                      data-vv-as="URL'en"
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </template>
              </popup-to-dialog-adapter>

              <!-- edit utm_source fields -->
              <popup-to-dialog-adapter
                title="Landing page"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator || isBusiness"
                @submit="saveEditChanges('utm_source', campaignCopy.utm_source)"
                @open="
                  updateCopyValue($event, 'utm_source', campaign.utm_source)
                "
                v-if="!isInfluencer"
              >
                <template v-slot:activator>
                  <v-list-item
                    @mouseover="hoverIndex = 'utmSource'"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 'utmSource' && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <template v-if="campaign.utm_source">{{
                          campaign.utm_source
                        }}</template>
                        <i v-else>X</i>
                      </v-list-item-title>
                      <v-list-item-title>utm source field</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <div class="d-flex flex-column">
                    <v-text-field
                      label="Source utm"
                      persistent-hint
                      hint=""
                      v-model="campaignCopy.utm_source"
                      data-vv-scope="utm_source"
                      data-vv-name="utm_source"
                      data-vv-as="URL'en"
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </template>
              </popup-to-dialog-adapter>

              <v-subheader class="light-grey">Payment</v-subheader>
            <!-- % per sale -->
            <popup-to-dialog-adapter
              v-if="campaign.commission_type === 'cost_per_action_percent'"
              title="Per sale"
              :onlyDialog="false"
              :allowSubmit="true"
              :editMode="editMode"
              @submit="
                saveEditChanges(
                  'cost_per_action_percent',
                  campaignCopy.cost_per_action_percent
                )
              "
              @open="
                updateCopyValue(
                  $event,
                  'cost_per_action_percent',
                  campaign.cost_per_action_percent
                )
              "
            >
              <template v-slot:activator>
                <v-list-item
                  class="transition"
                  @mouseover="hoverIndex = 14"
                  @mouseout="hoverIndex = null"
                  :class="{
                    'elevation-2': hoverIndex === 14 && editMode,
                    'cursor-pointer': editMode,
                  }"
                >
                  <v-list-item-icon>
                    <v-icon class="mt-1 menu-icon">fal fa-percent</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        campaign.cost_per_action_percent
                      }}%</v-list-item-title
                    >
                    <v-list-item-subtitle>Per sale</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:content>
                <v-flex column>
                  <div class="title text-center">
                    {{ campaignCopy.cost_per_action_percent }}% per sale
                  </div>
                  <v-slider
                    v-model="campaignCopy.cost_per_action_percent"
                    :min="0"
                    :max="100"
                  ></v-slider>
                </v-flex>
              </template>
            </popup-to-dialog-adapter>
              <!-- fixed per sale -->
              <popup-to-dialog-adapter
                v-if="campaign.commission_type === 'cost_per_action_fixed'"
                title="Per sale/lead"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="editMode"
                @submit="
                  saveEditChanges(
                    'cost_per_action_fixed',
                    campaignCopy.cost_per_action_fixed
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'cost_per_action_fixed',
                    campaign.cost_per_action_fixed
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 15"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 15 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-user-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{
                          campaign.cost_per_action_fixed
                        }}kr.</v-list-item-title
                      >
                      <v-list-item-title>Per sale/lead</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <v-flex column>
                    <div class="title text-center">
                      {{ campaignCopy.cost_per_action_fixed }}kr. per sale
                    </div>
                    <v-slider
                      v-model="campaignCopy.cost_per_action_fixed"
                      :min="0"
                      :max="1000"
                    ></v-slider>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
              <!-- fixed per click -->
              <v-list-item v-if="campaign.commission_type === 'cost_per_click' && isInfluencer">
                <v-list-item-content>
                  <a
                    href="https://intercom.help/Make-influence-com/da/articles/6248423-hvad-er-en-klik-kampagne"
                    target="_blank"
                  >
                    Read about click campaigns
                  </a>
                </v-list-item-content>
              </v-list-item>
              <popup-to-dialog-adapter
                v-if="campaign.commission_type === 'cost_per_click'"
                title="Per click"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="editMode"
                @submit="
                  saveEditChanges('cost_per_click', campaignCopy.cost_per_click)
                "
                @open="
                  updateCopyValue(
                    $event,
                    'cost_per_click',
                    campaign.cost_per_click
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 15"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 15 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon"
                        >fal fa-hand-point-up</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ campaign.cost_per_click }}kr.</v-list-item-title
                      >
                      <v-list-item-title>Per click</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <v-flex column>
                    <div class="title text-center">
                      {{ campaignCopy.cost_per_click }}kr. per click
                    </div>
                    <v-slider
                      v-model="campaignCopy.cost_per_click"
                      :min="0"
                      :max="100"
                    ></v-slider>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
              <!-- Max budget for CPC/CPM -->
              <popup-to-dialog-adapter
                v-if="campaign.max_commission_budget"
                title="Max. budget per. participant"
                :onlyDialog="false"
                :allowSubmit="isCommissionValid(campaignCopy.max_commission_budget)"
                :editMode="editMode"
                @submit="
                  saveEditChanges(
                    'max_commission_budget',
                    parseFloat(campaignCopy.max_commission_budget)
                  )
                "
                @open="
                  updateCopyValue(
                    $event,
                    'max_commission_budget',
                    parseFloat(campaign.max_commission_budget)
                  )
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 18"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 18 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-abacus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{
                          campaign.max_commission_budget
                        }}kr.</v-list-item-title
                      >
                      <v-list-item-title>
                        Max budget per participants
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <v-flex column>
                    <small class="font-italic primary--text">
                      Doesn't change for existing participants
                    </small>

                    <v-text-field
                      @change.native="isCommissionValid(campaignCopy.max_commission_budget)"
                      v-model="campaignCopy.max_commission_budget"
                      type="number"
                      suffix="kr."
                      v-validate="{
                        rules: {
                          decimal: 2,
                          required: true,
                          min_value: 100 }
                      }"
                      data-vv-name="max_commission_budget"
                      :error-messages="errors.collect('max_commission_budget')"
                    ></v-text-field>
                  </v-flex>
                </template>
              </popup-to-dialog-adapter>
              <v-subheader class="light-grey">Contact person</v-subheader>
              <!-- edit agency -->

              <popup-to-dialog-adapter
                title="Min. follower requirements"
                :onlyDialog="false"
                :allowSubmit="true"
                :editMode="isModerator"
                @submit="
                  saveEditChanges('agency_uuid', campaignCopy.agency_uuid)
                "
                @open="
                  updateCopyValue($event, 'agency_uuid', campaign.agency_uuid)
                "
              >
                <template v-slot:activator>
                  <v-list-item
                    class="transition"
                    @mouseover="hoverIndex = 16"
                    @mouseout="hoverIndex = null"
                    :class="{
                      'elevation-2': hoverIndex === 16 && editMode,
                      'cursor-pointer': editMode,
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon class="mt-1 menu-icon">fal fa-user</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ contactPerson }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        contactEntity
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:content>
                  <agencies-select
                    v-model="campaignCopy.agency_uuid"
                    :return-object="false"
                    clearable
                  ></agencies-select>
                </template>
              </popup-to-dialog-adapter>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import ChannelCategorySelect from "../selects/ChannelCategorySelect.vue";
import CampaignImage from "../images/CampaignImage.vue";
import EmojiPicker from "../pickers/EmojiPicker.vue";
import PopupToDialogAdapter from "../common/PopupToDialogAdapter.vue";
import SimpleRichTextInput from "../inputs/SimpleRichTextInput.vue";
import DatePicker from "@/components/common/filters/DatePicker.vue";
import AgenciesSelect from "../moderator/selects/AgenciesSelect.vue";

export default {
  components: {
    CampaignImage,
    ChannelCategorySelect,
    AgenciesSelect,
    EmojiPicker,
    PopupToDialogAdapter,
    SimpleRichTextInput,
    DatePicker,
  },
  filters: { friendly_numbers, local_numbers, utc_to_local_datetime },
  props: {
    campaign: {
      required: true,
      default: () => {}, // Default object
    },
    flat: {
      required: false,
      type: Boolean,
      default: false,
    },
    mode: {
      required: false,
      type: String,
      default: "default",
    },
  },
  data: () => ({
    applyDialog: false,
    reportDialog: false,
    // edit ability data variables
    campaignCopy: {},
    hoverIndex: false,
    today: null,
    tomorrow: null,
    start: null,
    end: null,
    day: 1000 * 60 * 60 * 24,
    duration: null,
    minFollowersArray: [0, 5000, 10000, 25000, 50000, 100000],
    selectedRef: null,
    businessDescription: null,
    tempCategory: null,
    landingSelectValue: null,
    displayRedirectInput: false,
    redirectUrl: "",
    maxParticipants: 0,
    activeCampaigns: 0,
  }),
  computed: {
    ...mapGetters("core/auth", [
      "isAgency",
      "isBusiness",
      "isInfluencer",
      "isModerator",
      "isManaged",
    ]),
    allowedActiveCampaigns() {
      return this.isBusiness ? this.campaign.business.allowed_active_campaigns : 0
    },
    hasMultipleDescriptions() {
      return (
        this.campaign.recommendations_description ||
        this.campaign.promotion_material_description ||
        this.campaign.product_description
      );
    },
    contactPerson() {
      if (this.campaign.agency) {
        return this.campaign.agency.user.first_name;
      }
      return this.campaign.business.user.first_name;
    },
    contactEntity() {
      if (this.campaign.agency) {
        return this.campaign.agency.name;
      }
      return this.campaign.business.display_name;
    },
    editMode() {
      if (this.mode === "edit") {
        const isEnded = Math.ceil(this.end.diff(this.today) / this.day) <= 0;
        if (isEnded) {
          return false;
        }
        if (this.isBusiness && this.campaign.agency) {
          return false;
        }
        return !this.isInfluencer;
      } else {
        return false;
      }
    },
    allowDeepLinking() {
      if (!this.isModerator) {
        return !this.campaign.allow_deep_linking;
      } else {
        return true;
      }
    },
    minDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    maxDate() {
      return this.$moment()
        .add(12, "months")
        .format("YYYY-MM-DD");
    },
    minEndDate() {
      return this.$moment()
        .add(24, "hours")
        .format("YYYY-MM-DD");
    },
    startingAt() {
      return this.$moment(this.campaignCopy.starting_at).format("YYYY-MM-DD");
    },
    endingAt() {
      return this.$moment(this.campaignCopy.ending_at).format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions("core/campaigns", ["updateCampaign", "loadCampaignsSummary"]),
    ...mapActions("business", ["updateBusiness"]),
    getActiveCampaigns() {
      const params = {
        draft: false,
        active_campaigns: true,
      };

      this.loadCampaignsSummary(params)
        .then((campaigns) => {
          this.activeCampaigns = campaigns.response.length;
        })
        .catch((err) => console.error(err));
    },
    setEditData() {
      this.today = this.$moment();
      this.tomorrow = this.$moment().add(1, "days");
      this.start = this.$moment(this.campaignCopy.starting_at);
      this.end = this.$moment(this.campaignCopy.ending_at);
      const diff = this.end.diff(this.start);
      this.duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
      this.campaignCopy.agency_uuid = this.campaign.agency
        ? this.campaign.agency.uuid
        : null;
      if (this.campaignCopy.url) {
        const businessUrl = this.stripProtocol(
          this.campaignCopy.business.website
        );
        const valueUrl = this.stripProtocol(this.campaignCopy.url);
        if (businessUrl === valueUrl) {
          this.landingSelectValue = valueUrl;
          this.displayRedirectInput = false;
          this.redirectUrl = valueUrl;
        } else {
          this.landingSelectValue = "custom";
          this.displayRedirectInput = true;
          this.redirectUrl = valueUrl;
        }
      }
    },
    saveEditChanges(field, value, isCategory) {
      this.saveCampaign(field, value, isCategory);
    },
    openBusinessDescription(event) {
      this.businessDescription = this.campaign.business.description;
      this.selectedRef = event;
      // reset editor value if reopen
      this.$nextTick(() => {
        this.$refs.businessDescriptionRef.resetValue();
      });
    },
    updateCopyValue(event, field, value, editorRef) {
      this.campaignCopy[field] = value;
      this.selectedRef = event;
      // reset editor value if reopen
      if (editorRef) {
        this.$nextTick(() => {
          this.$refs[editorRef].resetValue();
        });
      }
    },
    selectLandingUrl(event) {
      if (event === "custom") {
        this.redirectUrl = `${this.campaign.business.website}/`;
        this.displayRedirectInput = true;
      } else {
        this.redirectUrl = event;
        this.displayRedirectInput = false;
      }
    },
    startDateChange(event) {
      this.campaignCopy.starting_at = this.$moment(event).format(
        "YYYY-MM-DD  hh:mm:ss"
      );
    },
    endDateChange(event) {
      this.campaignCopy.ending_at = this.$moment(event).format(
        "YYYY-MM-DD  hh:mm:ss"
      );
    },
    stripHtmlTags(value) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = value;
      return (tmp.textContent || tmp.innerText || "").replace(/  +/g, " ");
    },
    saveCampaign(field, value, isCategory) {
      const params = {};
      params[field] = value;
      // fix if we remove Campaign we need sent null because if empty server backed with error
      if (value === undefined) {
        params[field] = null;
      }
      if (field === "agency_uuid" && !value) {
        params[field] = "";
      }
      // add protocol if non-protocol url
      if (field === "url") {
        if (value.includes("http://") || value.includes("https://")) {
          params[field] = value;
        } else {
          params[field] = `http://${value}`;
        }
      }
      params.uuid = this.campaign.uuid;
      this.updateCampaign(params).then(
        (updateCampaign) => {
          // save new value
          this.campaign[field] = value;
          // if category change
          if (isCategory) {
            this.campaign.category = this.tempCategory;
          }
          // if agency
          if (field === "agency_uuid") {
            this.campaign.agency = updateCampaign.agency;
          }
          this.campaignCopy = this.cloneDeep(this.campaign);
          this.selectedRef.close();
          this.setSnackSuccess("Saved");
          this.$forceUpdate();
        },
        () => {
          this.campaignCopy[field] = this.campaign[field];
          this.selectedRef.loading = false;
          this.setSnackError("Something went wrong");
        }
      );
    },
    saveBusinessDescription() {
      const params = {
        description: this.businessDescription,
        business_uuid: this.campaign.business.uuid,
      };
      this.updateBusiness(params)
        .then(() => {
          this.campaign.business.description = this.businessDescription;
          this.selectedRef.close();
          this.setSnackSuccess("Company details saved");
        })
        .catch(() => {
          this.selectedRef.loading = false;
          this.setSnackError("Company details couldn't save");
        });
    },
    stripProtocol(url) {
      return url.replace(/(^\w+:|^)\/\//, "");
    },
    isCommissionValid(max_commission_budget) {
      if( max_commission_budget >= 100) {
        return true
      }
      return false;
    },
  },
  created() {
    this.campaignCopy = this.cloneDeep(this.campaign);
    this.setEditData();
    // TODO: Move to mixin
    const _this = this;
    this.$validator.extend("business_website_match", {
      getMessage() {
        return "URL has to match company website domain";
      },
      validate(value) {
        let campaignUrl = value
          .toLowerCase()
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        let businessUrl = "";
        // If agency use business from value
        if (_this.campaign.business) {
          businessUrl = _this.campaign.business.website
            .toLowerCase()
            .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        }
        // else {
        //   businessUrl = _this.user.business.website
        //     .toLowerCase()
        //     .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        // }
        return campaignUrl.indexOf(businessUrl) !== -1;
      },
    });
    this.$validator.localize( 'en',{
      custom: {
          max_commission_budget: {
            min_value: 'The commission budget must be 100 DKK or more',
          }
      }
    });

    this.getActiveCampaigns();

  },
};
</script>
<style lang="scss" scoped>
pre {
  font-family: Roboto, sans-serif;
  white-space: pre-line;
}
.campaign-black {
  color: #000000 !important;
}
.transition {
  transition: all 0.3s ease-in-out;
}
</style>
