<template>
  <v-dialog max-width="85%" v-model="showDialog">
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Upload campaign image</div>
          <div class="subtitle-1 light-grey">
            Image is used dynamically, and will adapt to the unit it's being shown on
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-layout row>
            <v-flex sm6 xs12>
              <v-list tile dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>fal fa-save</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>File size</v-list-item-title>
                    <v-list-item-subtitle>
                      Max 1MB (<a
                        href="https://compressjpeg.com/"
                        target="_blank"
                        >Compress JPG</a
                      >,
                      <a href="https://compresspng.com/" target="_blank"
                        >Compress PNG</a
                      >)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>fal fa-images</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>File types</v-list-item-title>
                    <v-list-item-subtitle>
                      JPG or PNG
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>fal fa-arrows</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >Dimensions (recommended)</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      1920x1080px
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>fal fa-lightbulb-exclamation</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Tips and tricks</v-list-item-title>
                    <v-list-item-subtitle>
                      Avoid text, as it's used in multiple contexts
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex sm6 xs12>
              <file-upload-input
                v-model="image"
                :max-size="1"
                hint="Maks. 1 MB"
              ></file-upload-input>
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  :disabled="!image || !image.file"
                  :loading="savingImage"
                  @click="saveImage()"
                >
                  Upload
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider class="mt-3 mb-3"></v-divider>

        <div class="title mb-3">Campaign page (desktop)</div>

        <v-img
          :src="imageUrl"
          :aspect-ratio="aspect"
          max-height="500px"
        ></v-img>

        <v-divider class="mt-3 mb-3"></v-divider>

        <div class="title mb-3">Campaign page (tablet)</div>

        <v-img
          :src="imageUrl"
          :aspect-ratio="aspect"
          width="736px"
          height="380px"
        ></v-img>

        <v-divider class="mt-3 mb-3"></v-divider>

        <div class="title mb-3">Campaign page (mobile)</div>

        <v-img
          :src="imageUrl"
          :aspect-ratio="aspect"
          width="343px"
          height="202px"
        ></v-img>

        <v-divider class="mt-3 mb-3"></v-divider>

        <div class="title mb-3">List view</div>

        <v-img
          :src="imageUrl"
          :aspect-ratio="aspect"
          width="317px"
          height="185px"
        ></v-img>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Close</v-btn>
        <v-btn
          color="primary"
          :disabled="!image || !image.file"
          :loading="savingImage"
          @click="saveImage()"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUploadInput from "../inputs/FileUploadInput";
import { mapActions } from "vuex";

export default {
  components: { FileUploadInput },
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    campaign: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    image: null,
    savingImage: false,
    aspect: 1.7 // 1.7 is good for everything but desktop
  }),
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    imageUrl() {
      if (this.image === null) {
        if (this.campaign.image_url) {
          return this.campaign.image_url;
        }

        return "/img/campaign_default.jpg";
      }

      return this.image.url;
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["updateCampaignImage"]),
    saveImage() {
      this.savingImage = true;

      let params = {
        uuid: this.campaign.uuid,
        image: this.image.file
      };
      this.updateCampaignImage(params).then(
        response => {
          this.setSnackSuccess("Saved");
          this.campaign.image_url = response.image_url;
          this.savingImage = false;
          this.showDialog = false;
          this.$emit("save", this.campaign.uuid);
        },
        error => {
          console.log(error);
          this.setSnackError("Something went wrong");
          this.savingImage = false;
        }
      );
    }
  },
  created() {}
};
</script>
